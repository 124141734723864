<template>
  <div class="popUpScrollList orderReport">

    <div class="element materialShadow" v-if="relevantReportTypes.length >= 2">
      <div>
        <h1 class="reportRecipientsHeadline">{{  x('chooseReportType') }}:</h1>
        <select class="lsc-input lsc-rounded select-report-type" v-model="selectedReportIndex">
          <option v-for="(reportType, index) in relevantReportTypes"
                  :key="reportType.niceName"
                  :value="index">
            {{ x(`rapportType.${reportType.id}`) }}
          </option>
        </select>
      </div>
    </div>

    <div class="element materialShadow">

      <h2 class="reportRecipientsHeadline">{{ x('recipients') }}:</h2>
      <report-extra-recipients v-model="extraRecipients"
                               :visit-ids="feedItemIds"
                               :report-type-id="selectedReportType.id"
      />
      <textarea class="reportRecipients lsc-rounded"
                v-if="showExtraRecipientBox"
                v-model="emailRecipients"/>
      <p v-if="reportRecipientsError" class="reportRecipientsError">
        {{ x('invalidEmails') }}
      </p>

      <p class="checkboxLine" v-if="showIncludeExcel">
        <checkbox v-model="includeExcel"/>
        {{ x('includeExcel') }}
      </p>
      <p class="checkboxLine" v-if="showIncludeImages">
        <checkbox v-model="includeImages"/>
        {{ x('includeImages') }}
      </p>
      <p v-if="aboveBatchThreshold" class="reportRecipientsError">
        {{ x('tooManyReportsChosen') }}{{ batchThreshold }}
      </p>

      <button type="button" class="button lsc-button lsc-rounded sendReport" @click="orderReport"
              :disabled="
              this.isSendingReport ||
              this.didSendReport ||
              this.aboveBatchThreshold ||
              this.allRecipients.length === 0">
        <template v-if="didSendReport">
          <icon name="fa-send"/>
          {{ x('reportOrdered') }}
        </template>
        <template v-else-if="isSendingReport">
          <icon name="fa-cog fa-spin"/>
          {{ x('orderingReportDots') }}
        </template>
        <template v-else>
          <icon name="fa-send"/>
          {{ x('orderReport') }} ({{ feedItemIds.length }} {{ x('visits') }})
        </template>
      </button>

    </div>
  </div>
</template>

<script>
  import { uniq } from 'lodash';
  import { mapState, mapGetters } from 'vuex';
  import * as Email from '@/classes/email-helper';
  import { httpPost } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import Checkbox from '@/components/Checkbox';
  import ReportExtraRecipients from '@/components/Pages/ReportExtraRecipients';
  import ReportOptions from '@/enums/report-options';
  import EmailSources from '@/enums/email-sources';
  import translate from '../Mixins/Translate';

  /**
   * This shows a popup UI for creating a report for multiple visits in the app.
   */
  export default {
    name: 'feed-report',
    components: { ReportExtraRecipients, Checkbox },
    emits: [ComponentEventNames.didLoad],
    mixins: [translate],
    data() {
      return {
        isSendingReport: false,
        didSendReport: false,
        emailRecipients: '',
        reportRecipientsError: false,
        includeExcel: false,
        includeImages: false,
        // Recipients from the ReportExtraRecipients component
        extraRecipients: [],
        selectedReportIndex: 0,
      };
    },
    computed: {
      // How many visits may be selected for this report to be ordered
      batchThreshold() {
        if (!this.selectedReportType) return 0;
        return this.selectedReportType.reportMaxBatch;
      },
      aboveBatchThreshold() {
        return this.feedItemIds.length > this.batchThreshold;
      },
      allRecipients() {
        let combinedRecipients = [...this.extraRecipients];

        const manualEmails = Email.cleanAndSplitEmailRecipients(this.emailRecipients);
        if (manualEmails.length > 0) {
          combinedRecipients = combinedRecipients.concat(manualEmails);
        }

        combinedRecipients = uniq(combinedRecipients);
        return combinedRecipients;
      },
      selectedReportType() {
        return this.relevantReportTypes[this.selectedReportIndex];
      },
      jobOptions() {
        return this.selectedReportType.jobOptions || [];
      },
      showIncludeExcel() {
        return this.jobOptions.includes(ReportOptions.includeExcel);
      },
      showIncludeImages() {
        return this.jobOptions.includes(ReportOptions.includeImages);
      },
      showExtraRecipientBox() {
        return this.selectedReportType.emailOptions.includes(EmailSources.additional);
      },
      ...mapState(['config', 'feedItemIds', 'user']),
      ...mapState('FeedStore', {
        feedItems: 'items',
      }),
      ...mapGetters('ReportStore', {
        relevantReportTypes: 'getReportTypesForFeed',
      }),
    },
    async mounted() {
      this.$emit(ComponentEventNames.didLoad);
      console.log('selected report type: ', this.selectedReportType);
    },
    methods: {
      async orderReport() {
        console.log('orderReport');
        if (this.isSendingReport || this.didSendReport) return;

        this.reportRecipientsError = !Email.validateEmails(this.emailRecipients);
        console.log('reportRecipientsError: ', this.reportRecipientsError);
        if (this.reportRecipientsError) return;

        const emailRecipientArray = this.allRecipients;
        const emailRecipients = Email.makeString(emailRecipientArray);
        this.isSendingReport = true;
        console.log('Email recipients: ', emailRecipients);
        const data = {
          emailRecipients,
          visitIds: this.feedItemIds,
          reportType: this.selectedReportType.id,
        };
        // These options should only be included if they are relevant for the selected report type.
        if (this.showIncludeExcel) {
          data.includeExcel = this.includeExcel;
        }
        if (this.showIncludeImages) {
          data.includeImages = this.includeImages;
        }
        try {
          await httpPost('orderreport', data);
          this.didSendReport = true;
        } finally {
          this.isSendingReport = false;
        }

        setTimeout(() => {
          this.$store.commit('popPopup');
        }, 3000);
      },
    },
  };
</script>

<style lang="scss">

.select-report-type {
  width: 100%;
  margin-bottom: 10px;
}

button.sendReport {
  margin: 10px 0 0 0;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #cecece;
  background: black;
}

.reportRecipientsHeadline {
  width: 100%;
  padding: 10px 10px 0 10px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--ThemeTakePicH2Color);
}

.reportRecipients {
  width: 100%;
  border: 1px solid silver;
  padding: 10px;
  min-height: 120px;
}

.reportRecipientsError {
  color: red;
}

.recipientsInstructions {
  font-size: 12px;
  font-style: italic;
  color: #606060;
}

.checkboxLine {
  margin: 5px 0;
}
</style>
